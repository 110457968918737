<template>
    <v-card
        elevation="10"
        class="mx-auto"
        >

        <v-toolbar
            cards                
            flat
            outlined
            dark
            color="red"
        >
            <v-card-text class="text-h6 font-weight-regular">
            Achtung!
            </v-card-text>
            
            <v-spacer> </v-spacer>
            <v-btn 
                @click= "close"
                icon>
                <v-icon>mdi-close-thick</v-icon>
            </v-btn>
            
        </v-toolbar>
        <v-divider></v-divider>
        <v-container class="my-4">
            <v-row>
                <v-col cols="12">
                    <v-card-text justify="center" class="text-h6 font-weight-regular text-center my-n2">
                    {{text}}
                    
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card-text class="text-body-1 font-weight-regular text-center">
                    {{delName}}
                    </v-card-text>
                </v-col>
            </v-row>
        </v-container>

        <v-divider></v-divider>
        <v-card-actions>
            
            <v-btn outlined @click="close">Schließen</v-btn>
            <v-spacer> </v-spacer>
            <v-btn outlined color="error" @click="deleteItemConfirm">Ja</v-btn>
            
        </v-card-actions>
    </v-card>
</template>

<script>

export default {

    props:{
        text: {
               type: String,
               default: 'xxx',
               required: true
           },
        delName: {
               type: String,
               default: '',
               required: false
           },
    },

    data () {
        return {
            name: "AddEditUser",

        }
    },

    computed: {
 
    },
    methods:{
         
        deleteItemConfirm () {
            this.message = "";
             this.$emit('deleteItemConfirm', this.item)
                     
        },

        close () {
            this.visible = false
            this.$emit('close')
      
        },


    },
}
</script>


            